@import 'vars';

#ads_top {
  @media #{$desktop-device}{
    width: 728px;
    min-height: 90px;
    float: left;
  }
  @media #{$tablet-device}{
    display: none;
  }
  @media #{$mobile-device}{
    display: none;
  }
}

#ads_top_mobile {
  margin: 10px -15px 0 -15px;
  @media #{$desktop-device}{
    display: none;
  }
}

#ads_right {
  width: 300px;
  padding-top: 15px;
  clear: both;
  @media #{$mobile-device}{
    display: none;
  }
  @media #{$tablet-device}{
    width: 100%;
  }
  & img {
    @media #{$tablet-device}{
      width: 100%;
    }
  }
}

#ads_bottom {
  margin: 10px 0;
  @media #{$desktop-device}{
    display: none;
  }
  @media #{$tablet-device}{
    display: none;
  }
  & img {
    @media #{$tablet-device}{
      width: 100%;
    }
    @media #{$mobile-device}{
      width: 100%;
    }
  }
}

#result-block {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  float: left;
  padding: 15px 30px;
  @media #{$mobile-device}{
    text-align: center;
  }
  & p {
    margin-bottom: 0.5em;
    & span {
      font-weight: bold;
    }
  }
}

.navbar-toggler {
  position: relative;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  >.container{
    flex: 1 1 auto;
  }
  & .navbar {
    padding: 0.2rem 1rem;
    .top_new_menu {
      display: flex;
      justify-content: space-around;
      flex: 1 1 auto;
      margin-left: 20px;
      a {
        color: $color-h1;
        font-size: 15pt;
      }
    }
    & .navbar-toggler {
      padding: 0.05rem 0.75rem;
    }
  }
  & > .container {
    & > .row {
      @media #{$mobile-device}{
        flex-flow: column-reverse;
      }
      & > .col-lg-8 {
        @media #{$desktop-device}{
          width: 728px;
          flex: 0 0 728px;
        }
      }
      & > .col-lg-4 {
        @media #{$desktop-device}{
          width: 330px;
          flex: 0 0 330px;
        }
      }
      & > .col-sm-8 {
        @media #{$tablet-device}{
          width: 500px;
          flex: 0 0 500px;
        }
      }
      & > .col-sm-4 {
        @media #{$tablet-device}{
          width: 240px;
          flex: 0 0 240px;
        }
      }
    }
  }
}

.main-menu {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  float: left;
  margin-bottom: 20px;
  @media #{$mobile-device}{
    display: none;
  }
  & > a {
    width: 80%;
    padding: 10px;
    background: #FF5986;
    border-radius: 8px;
    color: white;
    display: block;
    margin: 15px auto;
    text-align: center;
    &:hover, &.active {
      color: white;
      font-weight: bold;
      text-decoration: none;
    }
  }
}

h1 {
  color: $color-h1;
  font-size: 1.5rem !important;
}

h2 {
  color: $color-h1;
  font-size: 1.2rem !important;
}

h3 {
  color: $color-h1;
  font-size: 1rem !important;
}

.description {
  text-align: justify;
}

.footer {
  background: #7B54BC;
  margin-top: 40px;
  color: white;
  & .footer-menu {
    padding: 20px 0;
    & a {
      color: white;
      clear: both;
      display: block;
      &:hover, &.active {
        color: white;
        font-weight: bold;
        text-decoration: none;
      }
    }
  }
}