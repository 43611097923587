// Responsive Variables
$desktop-device : 'only screen and (min-width: 1048px)';
$tablet-device:'only screen and (min-width: 768px) and (max-width: 1048px)';
$mobile-device:'only screen and (max-width: 767px)';
$spinner-width: 420px;
$spinner-height: 420px;
$spinner-width-mob: 340px;
$spinner-height-mob: 340px;
$spinner-btn-width: 105px;
$spinner-btn-height: 105px;
$spinner-btn-width-mob: 105px;
$spinner-btn-height-mob: 105px;
$color-h1: #772FEB